import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection3() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <>
            <div className="w-full h-auto bg-taxi mx-auto justify-center flex items-center pt-52 lg:pt-36 ">
                <div className="w-4/5 h-4/5 flex flex-col lg:flex-row  justify-center py-0 lg:py-14 ">

                    <div className="w-full h-auto lg:h-auto lg:w-[50%]  flex mx-auto justify-center lg:justify-start items-center static lg:relative">
                        <img  
                        src={rpdata?.gallery?.[0]}
                        className="md:-ml-14 rounded-2xl"
                        alt="companyLogo" 
                        data-aos="fade-right" 
                        data-aos-duration="3000"/>
                    </div>
                    <div className=" w-full lg:w-1/2 grid place-content-center h-full px-6 py-10">
                        <h1 className=" text-center lg:text-start font-bold text-blue-500">
                            {rpdata?.dbSlogan?.[1].slogan}
                        </h1>
                        <p className="text-center lg:text-start text-gray-200 md:text-gray-400">{rpdata?.dbValues?.[1].description}</p>
                        <div className="lg:block flex justify-center md:justify-start lg:justify-start mx-auto lg:mx-0">
                            <ButtonContent />
                        </div>
                    </div>

                </div>

            </div>
        </>
    );
}
export default HeroSection3;