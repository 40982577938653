import React, { useContext, useState } from "react";
import { GlobalDataContext } from "../../../context/context";
import MenuList from "./MenuList";
import { GiHamburgerMenu } from "react-icons/gi";

const Header7 = () => {
  const { rpdata } = useContext(GlobalDataContext);
  const [isOpen, setOpen] = useState(false);
  return (
    <header className="absolute right-[3%] left-[3%] z-[15] px-4 lg:px-6 my-6 rounded-[80px]">
      <div className="flex  lg:flex-row">
        <div className="w-full mr-0 md:w-[75%] lg:w-[25%] lg:mr-10 mt-0 lg:-mt-[30px] mb-0 lg:-mb-[50px] rounded-lg">
          <img
            src={rpdata?.dbPrincipal?.logo}
            alt="logo"
            loading="lazy"
            className=" w-full md:w-[80%] mx-auto pt-3 pb-5 "
          />
        </div>

        <div className="block w-[40%] lg:w-[75%] self-center ">
          <div className="flex justify-end">
            <div className="w-[50%] lg:w-[100%] flex items-center justify-end">
              <nav>
                <div className="hidden md:block">
                  <MenuList classes={"text-white"} />
                </div>

                <div
                  className="md:hidden flex justify-center  py-1 rounded-md w-[80px] h-[40px] bg-white"
                  onClick={() => setOpen(true)}
                >
                  <GiHamburgerMenu className="w-full h-full" />
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {isOpen ? (
        <div className="fixed z-50 top-0 right-0 left-0 bg-[#111] w-full h-screen overflow-y-auto pb-10">
          <div className="flex justify-end w-[90%] mx-auto relative">
            <button
              className="font-bold text-[20px] mt-5 text-white"
              onClick={() => setOpen(false)}
            >
              X
            </button>
          </div>
          <div className="w-[60%] mx-auto pb-10">
            <img
              src={rpdata?.dbPrincipal?.logo}
              alt="logo"
              loading="lazy"
              className="w-full"
            />
          </div>
          <div className="w-4/5 mx-auto ">
            <MenuList classes="flex-col gap-3 text-white" />
          </div>
        </div>
      ) : null}
    </header>
  );
};

export default Header7;
